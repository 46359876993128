html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
}
// varbackground: #15243a;

:root {
  /* --primaryColor:#222967; */

  --primaryColor: #15243a;
  --form_bg_color: white;
  --color-dark: #252525;
  --color-white: #ffffff;
  --color-gray: #d9d9d9;
  --color-blue-dark: #363070;
  --color-green: #5baa8b;
  --color-mehndi: #ceb745;
  --color-green-light: #8cc6c7;
  --color-pink: #e59a94;
  --color-pink-light: #efe3e3;
  --color-medium-pink: #f3b5c0;
  --color-dark-orange: #d08e2c;
  --color-dark-yellow: #e1a63c;
  --color-blue: #295ef5;
  --color-deep-orange: #ea6a45;
  --color-icon: #959595;
  --bg-quote-color: #f8f8f8;
  --bg-uploads: #f3f6ff;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
}
// font-family: "Manrope", sans-serif;
.ant-layout-header {
  background-color: var(--primaryColor) !important;
  padding: 0% !important;
  height: 90px !important;
}
.ant-layout-footer {
  padding: 0%;
}
.ant-card {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.ant-card-body {
  padding: 0px 20px !important;
}

:where(.css-dev-only-do-not-override-1r287do).ant-layout {
  background-color: #fff;
  font-family: Roboto, "sans-serif";
}
:where(.css-dev-only-do-not-override-1r287do).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  height: 40px !important;
}

:where(.css-dev-only-do-not-override-1r287do).ant-steps
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}

// textarea:where(.css-dev-only-do-not-override-1r287do).ant-input {
//   height: 70px !important;
// }

.ant-steps .ant-steps-item-custom >.ant-steps-item-container>.ant-steps-item-icon >.ant-steps-icon {
font-size: 22px;
font-weight: 500;
}

// textarea:where(.css-dev-only-do-not-override-d2lrxs).ant-input {
//   min-height: 80px;
// }

// :where(.css-dev-only-do-not-override-d2lrxs).ant-btn-default {
// background-color: #295ef5;

// }