$primary-color: #1890ff;
$form-bg: #f0f2f5;
$navbar-bg: #ffffff;
$text-color: #333;
$icon-color: #ffffff;
$btn-bg: $primary-color;
$btn-hover-bg: darken($primary-color, 10%);
$btn-color: #fff;
$btn-hover-color: #fff;
$box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

.main-navbar {
  background-color: $navbar-bg;
  box-shadow: $box-shadow;
  height: 90px;
}

.container-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  height: 90px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.menu-icon {
  margin-right: 15px;
  cursor: pointer;
}

.navbar-brand {
  flex-shrink: 0;
}

.navbar-nav {
  margin-top: 10px;
  font-size: 16px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  padding: 10px 15px;

  color: $text-color;
  &:hover {
    color: $primary-color;
  }
  &.active {
    font-weight: bold;
    color: $primary-color;
  }
}

.navbar-action {
  display: flex;
  align-items: center;
}

.search-wrapper {
  margin-right: 15px;
}

.language-select {
  margin-right: 15px;
}

.login-btn {
  margin: 5px 0 0 0px;
  height: 35px;
}

@media (max-width: 900px) {
  .navbar-nav {
    display: none;
  }

  .search-wrapper {
    display: none;
  }

  .navbar-action {
    justify-content: flex-end;
    width: 100%;
  }
}

@media (max-width: 900px) {
  .main-navbar {
    background-color: $navbar-bg;
    box-shadow: $box-shadow;
    height: 90px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .navbar-nav {
    display: none;
  }

  .search-wrapper {
    display: none;
  }

  .navbar-action {
    justify-content: flex-end;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .main-navbar {
    background-color: $navbar-bg;
    box-shadow: $box-shadow;
    height: 90px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .home-container {
    padding: 10px;
  }

  .hero-section {
    padding: 30px 10px;

    h1 {
      font-size: 2em;
    }

    p {
      font-size: 1em;
    }

    .ant-btn-primary {
      font-size: 1em;
      padding: 8px 20px;
    }
  }

  .features-section {
    .feature-card {
      padding: 15px;
    }
  }

  .images-section {
    .image-card {
      .ant-card-cover {
        height: 150px;
      }
    }
  }

  .solution-section {
    flex-direction: column;

    .solution-content {
      flex: 1 1 100%;
    }

    .stats-section {
      flex: 1 1 100%;
      flex-direction: column;

      .stat,
      .images {
        margin: 10px 0;
      }
    }
  }
}

@media (max-width: 480px) {
  .home-container {
    padding: 5px;
  }

  .hero-section {
    padding: 20px 5px;

    h1 {
      font-size: 1.5em;
    }

    p {
      font-size: 0.9em;
    }

    .ant-btn-primary {
      font-size: 0.9em;
      padding: 6px 15px;
    }
  }

  .features-section {
    .feature-card {
      padding: 10px;
    }
  }

  .images-section {
    .image-card {
      .ant-card-cover {
        height: 120px;
      }
    }
  }

  .solution-section {
    .solution-content {
      flex: 1 1 100%;
    }

    .stats-section {
      flex: 1 1 100%;
      flex-direction: column;

      .stat {
        h2 {
          font-size: 2em;
        }

        p {
          font-size: 1em;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .main-navbar {
    background-color: $navbar-bg;
    box-shadow: $box-shadow;
    height: 90px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .home-container {
    padding: 8px;
  }

  .hero-section {
    padding: 25px 10px;

    h1 {
      font-size: 1.8em;
    }

    p {
      font-size: 0.95em;
    }

    .ant-btn-primary {
      font-size: 0.95em;
      padding: 7px 18px;
    }
  }

  .features-section {
    .feature-card {
      padding: 12px;
    }
  }

  .images-section {
    .image-card {
      .ant-card-cover {
        height: 140px;
      }
    }
  }

  .solution-section {
    .solution-content {
      flex: 1 1 100%;
    }

    .stats-section {
      flex: 1 1 100%;
      flex-direction: column;

      .stat {
        h2 {
          font-size: 1.8em;
        }

        p {
          font-size: 0.9em;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .home-container {
    padding: 6px;
  }

  .hero-section {
    padding: 20px 8px;

    h1 {
      font-size: 1.6em;
    }

    p {
      font-size: 0.9em;
    }

    .ant-btn-primary {
      font-size: 0.9em;
      padding: 6px 16px;
    }
  }

  .features-section {
    .feature-card {
      padding: 10px;
    }
  }

  .images-section {
    .image-card {
      .ant-card-cover {
        height: 130px;
      }
    }
  }

  .solution-section {
    .solution-content {
      flex: 1 1 100%;
    }

    .stats-section {
      flex: 1 1 100%;
      flex-direction: column;

      .stat {
        h2 {
          font-size: 1.6em;
        }

        p {
          font-size: 0.85em;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .navbar-left {
    flex-direction: row;
    align-items: center;
  }

  .menu-icon {
    margin-right: 0;
  }

  .navbar-brand .logo {
    width: 60px;
    height: 50px;
  }

  .navbar-action {
    flex-direction: row;
    align-items: center;
    width: 65%;
    .language-select {
      width: auto;
      margin-bottom: 10px;
    }
    .login-btn {
      width: auto;
      margin-bottom: 10px;
    }
  }

  .home-container {
    padding: 2px;
  }

  .hero-section {
    padding: 10px 5px;

    h1 {
      font-size: 1.2em;
    }

    p {
      font-size: 0.8em;
    }

    .ant-btn-primary {
      font-size: 0.8em;
      padding: 4px 10px;
    }
  }

  .features-section {
    .feature-card {
      padding: 5px;
    }
  }

  .images-section {
    .image-card {
      .ant-card-cover {
        height: 80px;
      }
    }
  }

  .solution-section {
    .solution-content {
      padding: 5px;

      h1 {
        font-size: 1em;
      }

      p {
        font-size: 0.7em;
      }

      .learn-more-button {
        font-size: 0.7em;
        padding: 4px 10px;
      }
    }

    .stats-section {
      .stat {
        h2 {
          font-size: 1em;
        }

        p {
          font-size: 0.7em;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .navbar-left {
    flex-direction: column;
    align-items: flex-start;
  }

  .menu-icon {
    margin-right: 0;
  }

  .navbar-brand .logo {
    width: 55px;
    height: 45px;
  }

  .navbar-action {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    .language-select {
      width: auto;
      margin-bottom: 8px;
    }
    .login-btn {
      width: auto;
      margin-bottom: 8px;
    }
  }

  .home-container {
    padding: 4px;
  }

  .hero-section {
    padding: 15px 5px;

    h1 {
      font-size: 1.4em;
    }

    p {
      font-size: 0.85em;
    }

    .ant-btn-primary {
      font-size: 0.85em;
      padding: 5px 12px;
    }
  }

  .features-section {
    .feature-card {
      padding: 8px;
    }
  }

  .images-section {
    .image-card {
      .ant-card-cover {
        height: 110px;
      }
    }
  }

  .solution-section {
    .solution-content {
      padding: 4px;

      h1 {
        font-size: 0.9em;
      }

      p {
        font-size: 0.75em;
      }

      .learn-more-button {
        font-size: 0.75em;
        padding: 3px 7px;
      }
    }

    .stats-section {
      .stat {
        h2 {
          font-size: 1.2em;
        }

        p {
          font-size: 0.75em;
        }
      }
    }
  }
}

@media (max-width: 270px) {
  .navbar-left {
    flex-direction: column;
    align-items: center;
  }

  .menu-icon {
    margin-right: 0;
  }

  .navbar-brand .logo {
    width: 50px;
    height: 40px;
  }

  .navbar-action {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    .language-select {
      width: auto;
      margin-bottom: 5px;
    }
    .login-btn {
      width: auto;
      margin-bottom: 5px;
    }
  }

  .drawer-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .search-wrapper {
      width: 100%;
      margin-bottom: 10px;
    }

    .nav-list {
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;

      .nav-item {
        width: 100%;
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        color: $text-color;
        &:hover {
          color: $primary-color;
        }
        &.active {
          font-weight: bold;
          color: $primary-color;
        }
      }
    }
  }

  .home-container {
    padding: 2px;
  }

  .hero-section {
    padding: 5px;

    h1 {
      font-size: 1em;
    }

    p {
      font-size: 0.7em;
    }

    .ant-btn-primary {
      font-size: 0.7em;
      padding: 4px 10px;
    }
  }

  .features-section {
    .feature-card {
      padding: 2px;
    }
  }

  .images-section {
    .image-card {
      .ant-card-cover {
        height: 60px;
      }
    }
  }

  .solution-section {
    .solution-content {
      padding: 2px;

      h1 {
        font-size: 0.9em;
      }

      p {
        font-size: 0.6em;
      }

      .learn-more-button {
        font-size: 0.6em;
        padding: 2px 5px;
      }
    }

    .stats-section {
      .stat {
        h2 {
          font-size: 0.9em;
        }

        p {
          font-size: 0.6em;
        }
      }
    }
  }
}
