/* ScrollToTopButton.scss */

.scroll-to-top {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
  
    .scroll-button {
      background: #fff;
      color: #000;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;
      transition: opacity 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #000;
  
      &:hover {
        opacity: 0.8;
        background-color: #000;
        color: #fff;
      }
    }
  }
  